import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-textrea',
  templateUrl: './formly-custom-textrea.component.html',
  styleUrls: ['./formly-custom-textrea.component.scss']
})
export class FormlyCustomTextreaComponent extends FieldType<FieldTypeConfig> {

  constructor() {
      super();
  }
}
