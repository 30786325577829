import { Injectable } from '@angular/core';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { map, of, tap } from 'rxjs';
import { WPCountry } from '../models/shared/wpcountry.model';
import { Country } from '../models/shared/country.model';
import { ItemSelect } from 'src/app/dynamic-form/custom/models/select-item.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  cacheList: WPCountry[] = [];
  countryItems: ItemSelect[] = [];


  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient
  ) {
    this.countries().subscribe();
  }

  countries() {
    if (this.cacheList.length > 0) return of(this.cacheList);

    const url: string = `${this.appSettings.settings.url}/countries`;
    const api$ = this.httpClient.get<WPCountry[]>(url);

    return api$
      .pipe(
        map(result => {
          return result.filter(country => country.name.toLowerCase() != 'Israel'.toLowerCase())
        }),
        tap(result => this.cacheList = result),
      )
  }

  getCountryItems() {
    return this.countries()
      .pipe(
        map(countries => this.setCountryItems(countries)),
      );
  }

  filterByKeyword(keyword: string){
    const result = this.countryItems.filter(item => item.label.toLowerCase().includes(keyword.toLowerCase()));
    return of(result);
  }

  getById(id): Country{
    const wpCountry: WPCountry = this.cacheList.find(({code}) => code === id);
    return {
      id: wpCountry.code,
      name: wpCountry.name,
    }
  }

  private setCountryItems(countries: WPCountry[]) {
    this.countryItems = [];
    countries.map(country => {
      this.countryItems.push({
        id: country.code,
        label: country.name,
        subElements: country.states.map(state => {
          return { id: state.code, label: state.name, subElements: [] }
        }),
      })
    });

    return this.countryItems;
  }

}
