import { Injectable } from '@angular/core';
import { FormlyFieldProps, FormlyTemplateOptions } from '@ngx-formly/core';
import { of } from 'rxjs';
import { CountryService } from 'src/app/shared/services/country.service';
import { IndustryService } from 'src/app/shared/services/industry.service';
import { OccupationService } from 'src/app/shared/services/occupation.service';
import { WantedDealsService } from 'src/app/shared/services/wanted-deals.service';

@Injectable({
  providedIn: 'root'
})
export class CustomInputService {

  constructor(
    private industryService: IndustryService,
    private wantedDealsService: WantedDealsService,
    private countryService: CountryService,
    private occupationService: OccupationService
  ) { }

  public formlyCustomInput(config: { key: string, label: string, props: FormlyFieldProps }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'custom-input',
      props: {
        label: config.label,
        ...config.props
      }
    }
  }

  public formlyCustomTextArea(config: { key: string, label: string, props: FormlyFieldProps }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'formly-custom-textarea',
      props: {
        label: config.label,
        ...config.props
      }
    }
  }

  public formlyCustomRadio(config: { key: string, label: string, props: FormlyFieldProps }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'custom-radio-type',
      props: {
        label: config.label,
        name: config.key,
        options: config.props.options,
        ...config.props,
      }
    }
  }

  public formlyCustomCheckbox(config: { key: string, label: string, props: FormlyFieldProps }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'custom-checkbox-type',
      props: {
        label: config.label,
        name: config.key,
        options: config.props.options,
        ...config.props,
      }
    }
  }

  public formlyIndustryInput(config: { key: string, label: string, templateOptions: FormlyTemplateOptions }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'formly-industry-input',
      templateOptions: {
        label: config.label,
        required: true,
        search$: (item: string) => {
          if (!item || item.length == 0) {
            return this.industryService.getIndustries();
          }
          return this.industryService.filterIndustriesByKeyword(item);
        },
        ...config.templateOptions
      },
      expressions: {
        'props.disabled': '!model.myIndustry',
      },
    }
  }

  public formlyOfferInput(config: { key: string, label: string, model: any, templateOptions: FormlyTemplateOptions, expressions: any }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'formly-offer-input',
      templateOptions: {
        label: config.label,
        required: true,
        search$: (item: string) => {
          if (!item || item.length == 0) {
            return this.industryService.getSubIndustries(config.model.myIndustry);
          }
          return this.industryService.filterIndustriesByParentAndKeyword(config.model.myIndustry, item);
        },
        ...config.templateOptions
      },
      expressions: config.expressions,
    }
  }

  public formlyInterestsInput(config: { key: string, label: string, templateOptions: FormlyTemplateOptions }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'formly-interests-input',
      templateOptions: {
        label: config.label,
        required: true,
        search$: (item: string) => {
          if (!item || item.length == 0) {
            return this.industryService.getIndustries();
          }
          return this.industryService.filterIndustriesByKeywordOnGroup(item);
        },
        ...config.templateOptions
      },
    }
  }

  public formlyOccupationInput(config: { key: string, label: string, templateOptions: FormlyTemplateOptions }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'formly-occupation-input',
      templateOptions: {
        label: config.label,
        required: true,
        search$: (item: string) => {
          if (!item || item.length == 0) {
            return this.occupationService.getOccupations();
          }
          return this.occupationService.filterOccupationsByKeywordOnGroup(item);
        },
        ...config.templateOptions
      },
    }
  }

  public formlyLookingFor(config: { key: string, label: string, templateOptions: FormlyTemplateOptions }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'formly-lookingfor-input',
      templateOptions: {
        label: config.label,
        required: false,
        search$: (item: string) => {
          if (!item || item.length == 0) {
            return this.wantedDealsService.getWantedDeals();
          }
          return this.wantedDealsService.filterWantedDealsByKeyword(item);
        },
        ...config.templateOptions
      }
    }
  }

  public formlyCountryInput(config: { key: string, label: string, templateOptions: FormlyTemplateOptions }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'formly-country-input',
      templateOptions: {
        label: config.label,
        required: true,
        search$: (item: string) => {
          if (!item || item.length == 0) {
            return this.countryService.getCountryItems();
          }
          return this.countryService.filterByKeyword(item);
        },
      },
      expressions: {},
    }
  }

  public formlyCustumDropDownSelect(config: { key: string, label: string, options: string[], templateOptions: FormlyTemplateOptions }) {
    return {
      key: config.key,
      className: 'col-span-full',
      type: 'custom-unique-select',
      templateOptions: {
        label: config.label,
        search$: (item: string) => {
          if (!item || item.length == 0) {
            return of(config.options);
          }
          return of(
            config.options.filter((element: any) => element.label.toLowerCase().includes(item.toLowerCase()))
          );
        },
        ...config.templateOptions,
      },
      expressions: {},
    }
  }

  public confirmationStep(config: { label: string, templateOptions: FormlyTemplateOptions, expressions: any }) {
    return {
      key: 'confirmation',
      className: 'col-span-full',
      type: 'formly-confirmation',
      templateOptions: {
        label: config.label,
        ...config.templateOptions
      },
      expressions: config.expressions,
    }
  }
}
