import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Subject, debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from "rxjs";

@Component({
  selector: 'formly-occupation-input',
  templateUrl: './formly-occupation-input.component.html',
  styleUrls: ['./formly-occupation-input.component.scss']
})
export class FormlyOccupationInputComponent extends FieldType<FieldTypeConfig> implements OnDestroy {
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  showDropdown: boolean = false;
  selected = { label: '', value: '' };
  options$;

  ngOnInit() {
    this.options$ = this.search$.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      filter(v => v !== null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(this.to.search$),
    );
    this.options$.subscribe();
  }


  search($event) {
    this.open();
    this.formControl.setValue($event.target.value);
    this.search$.emit($event.target.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }

  toggle() {
    this.showDropdown = !this.showDropdown;
  }

  open() {
    this.showDropdown = true;
  }

  close() {
    this.showDropdown = false;
  }

  select(option) {
    if (this.selected.label == option.label) {
      this.selected = { label: '', value: '' };
      this.formControl.setValue('');
    } else {
      this.selected = option;
      this.formControl.setValue(option.label);
      this.close();
    }
  }

  closeWhenSelected(){
    if(this.selected.label.length > 0) this.close();
  }

}
