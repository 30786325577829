import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'qrcode'
})
export class QRCodePipe implements PipeTransform {

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
  ) { }


  transform(endpoint: string): Observable<SafeUrl> {
    return this.httpClient
      .get(endpoint, { responseType: 'blob' })
      .pipe(
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
      )
  }

}
