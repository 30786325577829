import { Injectable } from '@angular/core';
import { AppSettingService } from './app-setting.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationService, NotificationType } from '../components/notifications/notification.service';
import { UploadUtilsService } from './upload-utils.service';

@Injectable({
  providedIn: 'any'
})
export class FileUploaderService {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private uploadUtils: UploadUtilsService,
    private notificationService: NotificationService
  ) { }

  onFileSelect<T>($event: any, endpoint: string, maxSize: number, supportedTypes: string[]): Observable<T | null> {
    const file: File = $event.target.files[0];
    try {
      const canUpload = this.uploadUtils.beforeUpload(file, maxSize, supportedTypes);
      if (!canUpload) return of(null);
      return this.uploadFile<T>(file, endpoint);
    } catch (error: any) {
      console.log(error);
      this.notificationService.notify('Event not saved',  error.message , NotificationType.Error);
      return of(null);
    }
  }

  /**
   * Upload a file to backend
   * @param file file to upload
   * @returns FileUpload observable
   */
  private uploadFile<T>(file: File, endPoint: string): Observable<T | null> {
    const url: string = `${this.appSettings.settings.url}/${endPoint}`;
    const formData = new FormData();
    formData.append("file", file);
    return this.httpClient.patch<T>(url, formData);
  }
}
