<div class="pb-12">
    <h2 class="text-xl font-semibold leading-10 text-gray-900">{{"FORMS.CONFIRMATION" | translate }}</h2>
    <p class="mt-1 text-sm leading-6 text-gray-600">{{"FORMS.CONFIRM PROFILE INFO" | translate }}</p>
</div>

<div class="border-b border-gray-900/10 pb-12 mt-4">
    <h2 class="text-base font-semibold leading-7 text-gray-900">{{"FORMS.ABOUT ME" | translate }}</h2>
    <!-- <p class="mt-1 text-sm leading-6 text-gray-600">{{"FORMS.USE_PERMANENT_ADDRESS" | translate }}</p> -->
    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="salutation" class="block text-xs font-medium text-gray-900">{{"SALUTATION" | translate }}</label>
                <input type="text" name="salutation" id="salutation" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.salutation }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="firstname" class="block text-xs font-medium text-gray-900">{{"FIRST NAME" | translate }}</label>
                <input type="text" name="firstname" id="firstname" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.firstName }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
          <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label for="lastname" class="block text-xs font-medium text-gray-900">{{"LAST NAME" | translate }}</label>
              <input type="text" name="lastname" id="lastname" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.lastName }}" disabled>
          </div>
      </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="email" class="block text-xs font-medium text-gray-900">{{"EMAIL" | translate }}</label>
                <input type="text" name="email" id="email" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.email }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="mobilePhone" class="block text-xs font-medium text-gray-900">{{"MOBILE PHONE" | translate }}</label>
                <input type="text" name="mobilePhone" id="mobilePhone" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.mobilePhone }}" disabled>
            </div>
        </div>
    </div>
</div>

<div class="border-b border-gray-900/10 pb-12 mt-5">
    <h2 class="text-base font-semibold leading-7 text-gray-900">{{"PROFESSIONAL INFORMATION" | translate }}</h2>
    <!-- <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> -->

    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="country" class="block text-xs font-medium text-gray-900">{{"FORMS.COUNTRY" | translate }}</label>
                <input type="text" name="country" id="country" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ country }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="businessCity" class="block text-xs font-medium text-gray-900">{{"BUSINESS CITY" | translate }}</label>
                <input type="text" name="businessCity" id="businessCity" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.businessCity }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="address" class="block text-xs font-medium text-gray-900">{{"ADDRESS" | translate }}</label>
                <input type="text" name="address" id="address" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.address }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="company" class="block text-xs font-medium text-gray-900">{{"COMPANY" | translate }}</label>
                <input type="text" name="company" id="company" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.company }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="company" class="block text-xs font-medium text-gray-900">{{"FORMS.OCCUPATION" | translate }}</label>
                <input type="occupation" name="occupation" id="occupation" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.occupation }}" disabled>
            </div>
        </div>
    </div>
</div>


<div class="pb-12 mt-5">
    <h2 class="text-base font-semibold leading-7 text-gray-900">{{"FORMS.MY INTERRESTS" | translate }}</h2>
    <!-- <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> -->

    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="industry" class="block text-xs font-medium text-gray-900">{{"FORMS.MY INDUSTRY" | translate }}</label>
                <input type="text" name="industry" id="industry" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ myIndustry }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="myOffer" class="block text-xs font-medium text-gray-900">{{"FORMS.MY OFFER" | translate }}</label>
                <input type="text" name="myOffer" id="myOffer" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ myOffer }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="myInterests" class="block text-xs font-medium text-gray-900">{{"FORMS.MY INTERRESTS" | translate }}</label>
                <input type="text" name="myInterests" id="myInterests" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ myInterests }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="company" class="block text-xs font-medium text-gray-900">{{"FORMS.MY COMPANY" | translate }}</label>
                <input type="text" name="company" id="company" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ user.company }}" disabled>
            </div>
        </div>

        <div class="sm:col-span-3">
            <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label for="wantedDeals" class="block text-xs font-medium text-gray-900">{{"FORMS.LOOKING FOR" | translate }}</label>
                <input type="occupation" name="wantedDeals" id="wantedDeals" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-50" value="{{ wantedDeals }}" disabled>
            </div>
        </div>
    </div>
</div>
