import { Component, Input } from '@angular/core';
import { Form } from 'src/app/shared/models/event/form.model';

@Component({
  selector: 'event-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrl: './registration-form.component.scss'
})
export class RegistrationFormComponent {
  @Input('form') form: Form;
}
