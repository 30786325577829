import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { EventPageComponent } from './event-page/event-page.component';
import { EventRoutingModule } from './event-routing.module';
import { EventComponent } from './event.component';

import { TranslateModule } from '@ngx-translate/core';
import { ShareButtonDirective, provideShareButtonsOptions } from 'ngx-sharebuttons';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { RegistrationModule } from '../registration/registration.module';
import { RegistrationFormComponent } from './event-page/registration-form/registration-form.component';


@NgModule({
  declarations: [
    EventComponent,
    EventPageComponent,
    RegistrationFormComponent
  ],
  imports: [
    CommonModule,
    EventRoutingModule,
    SharedModule,
    RegistrationModule,
    TranslateModule,
    ShareButtonDirective,
    ShareButtons
  ],
  providers: [
    provideShareButtonsOptions(
      shareIcons()
    )
  ]
})
export class EventModule { }
