import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { ItemSelect } from 'src/app/dynamic-form/custom/models/select-item.model';
import { Industry } from '../models/shared/industry.model';
import { AppSettingService } from './app-setting.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  serverIndustries: Industry[] = [];
  industries: ItemSelect[] = [];

  constructor(
    private appSettings: AppSettingService,
    private eventService: EventService,
    private httpClient: HttpClient
  ) { }

  getIndustries(): Observable<ItemSelect[]> {
    if (this.industries.length > 0) {
      return of(this.industries);
    };

    const url: string = `${this.appSettings.settings.url}/industries/event/${this.eventService.event.id}`;
    const api$ = this.httpClient.get<Industry[]>(url);

    return api$.pipe(
      tap(result => this.serverIndustries = result),
      map(result => {
        let list: ItemSelect[] = [];
        result.map(item => {
          const parentIndId = item.parentIndustry.id;
          const index = list.findIndex(el => el.id == parentIndId);
          if (index == -1) {
            list.push({
              id: item.parentIndustry.id,
              label: item.parentIndustry.name,
              subElements: [{ id: item.id, label: item.name, subElements: [] }],
            })
          } else {
            list[index].subElements.push({
              id: item.id,
              label: item.name,
              subElements: [],
            })
          }
        });
        this.industries = list;
        return list;
      }),
    );
  }

  filterIndustriesByKeyword(keyword: string): Observable<any> {
    const result = this.industries.filter(industry => industry.label.toLowerCase().includes(keyword.toLowerCase()));
    return of(result);
  }

  filterIndustriesByKeywordOnGroup(keyword: string): Observable<any> {
    const results: ItemSelect[] = [];
    this.industries.map(industry => {
      if (industry.label.toLowerCase().includes(keyword)) {
        results.push(industry);
      } else {
        const subResults = industry.subElements.filter(subInd => subInd.label.toLowerCase().includes(keyword.toLowerCase()));
        if (subResults.length > 0) {
          results.push({
            ...industry,
            subElements: subResults,
          })
        }
      }
    });
    return of(results);
  }

  filterIndustriesByParentAndKeyword(parentIndustryId: string, keyword: string): Observable<any> {
    const result = this.industries.filter(industry => industry.id == parentIndustryId);
    if (!result || result.length == 0) return of([]);
    return of(result[0].subElements.filter(sub => sub.label.toLowerCase().includes(keyword)));
  }

  getSubIndustries(parentIndustryId: string): Observable<any> {
    const index = this.industries.findIndex(industry => industry.id == parentIndustryId);
    if (index >= 0) {
      return of(this.industries[index].subElements);
    }
    return of([]);
  }

  getById(industryId: string){
    let found;
    this.serverIndustries.map(industry => {
      if(industry.id == industryId) found = industry;
      if(industry.parentIndustry.id == industryId) found = industry.parentIndustry;
    })
    return found;
  }

}
