import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const authenticationGuard: CanActivateFn = (route, state) => {

  const auth: AuthenticationService = inject(AuthenticationService);

if (!auth.authenticated) {
    return auth.authenticate();
  }

  return auth.authenticated;
};
