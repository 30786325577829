import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventComponent } from './event.component';
import { EventPageComponent } from './event-page/event-page.component';
import { RegistrationComponent } from '../registration/registration.component';
import { RegistrationPageComponent } from '../registration/registration-page/registration-page.component';
import { RegistrationSuccessComponent } from '../registration/registration-success/registration-success.component';
import { RegistrationErrorComponent } from '../registration/registration-error/registration-error.component';

const routes: Routes = [
  {
    'path': 'event/:event',
    component: EventComponent,
    children: [
      { path: '', component: EventPageComponent },
      {
        path: 'register',
        component: RegistrationComponent,
        children: [
          { path: '', component: RegistrationPageComponent },
          { path: ':formId', component: RegistrationPageComponent },
          { path: 'success', component: RegistrationSuccessComponent },
          { path: 'error', component: RegistrationErrorComponent }
        ]
      },
    ]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule { }
