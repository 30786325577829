<nav aria-label="Progress">
    <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
        <li *ngFor="let step of field.fieldGroup; let index = index; let last = last" class="md:flex-1">
            <a (click)="setCurrentIndex(index)"
                [ngClass]='{"hover:border-main border-main/75": isCompleted(index) || isCurrent(index)}'
                class="group flex flex-col border-l-4  py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 border-gray-200  hover:border-gray-300">
                <span [ngClass]='{"group-hover:text-main text-main/75": isCompleted(index) || submitted}'
                    class="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    Step {{ index + 1 }} / {{ step.props.totalSteps }}
                </span>
                <span class="text-sm font-medium">{{ 'FORMS.' + step.props.stepTitle.toUpperCase() | translate}}</span>
            </a>
        </li>
    </ol>
</nav>

<formly-field [field]="field.fieldGroup[currentIndex]"></formly-field>

<div class="flex justify-end w-full" [ngClass]="{'flex justify-between': currentIndex != 0 }">
    <button (click)="previous()" *ngIf="currentIndex != 0" type="button"
        class="w-32 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-slate-700 hover:bg-gray-50">
        {{'FORMS.BACK' | translate}}
    </button>

    <button (click)="next()" *ngIf="!isLast()" type="button" [disabled]="!isValid(field.fieldGroup[currentIndex])"
        class="w-32 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm disabled:bg-main/40 disabled:cursor-none hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
        {{'FORMS.NEXT' | translate}}
    </button>

    <button *ngIf="isLast()" type="submit" (click)='onSubmit()' [disabled]="!form.valid"
        class="w-32 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
        {{'FORMS.SUBMIT' | translate}}
    </button>
</div>
