<!-- Main modal -->
<div *ngIf="openLanguage" id="default-modal" tabindex="-1" aria-hidden="true"
  class="mx-auto overflow-y-auto overflow-x-hidden fixed right-0 left-0 bottom-0 z-50 justify-center items-center bg-opacity-50 bg-slate-200 w-full md:inset-0 h-[calc(100%-1rem)] min-h-full">
  <div class="mx-auto my-auto mt-24 relative p-4 w-full max-w-2xl max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow">
      <button type="button" (click)="closeLanguageModal()"
        class="absolute top-1 end-1 text-gray-400 bg-transparent hover:text-gray-900 text-sm w-8 h-8 ms-auto inline-flex justify-center items-center rounded-full hover:bg-slate-100"
        data-modal-hide="popup-modal">
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
      <div class="my-4 p-4 md:p-5 text-center ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="mx-auto mb-4 w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
        </svg>


        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"> {{"LANGUAGES.CHOOSE_LANGUAGE" |
          translate}}</h3>
        <button data-modal-hide="popup-modal" type="button" (click)="switchLanguage('en')"
          class="me-2 inline-flex items-center py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none max-w-32 rounded-lg border border-gray-200 hover:bg-slate-300  focus:z-10 focus:ring-4 focus:ring-gray-100 bg-slate-200"
          [ngClass]="{'border-main': currentLanguage === 'en'}">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 me-2" viewBox="0 0 32 32">
            <rect x="1" y="4" width="30" height="24" rx="4" ry="4" fill="#071b65"></rect>
            <path
              d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
              fill="#fff"></path>
            <path d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z" fill="#b92932"></path>
            <path d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z" fill="#b92932"></path>
            <path
              d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
              fill="#fff"></path>
            <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
            <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
            <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
            <rect x="14" y="1" width="4" height="30" transform="translate(32) rotate(90)" fill="#b92932"></rect>
            <path d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z" fill="#b92932">
            </path>
            <path d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z" fill="#b92932">
            </path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"></path>
            <path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff" opacity=".2"></path>
          </svg>
          {{"LANGUAGES.en" | translate}}
        </button>
        <button data-modal-hide="popup-modal" type="button" (click)="switchLanguage('fr')"
          class="me-2 inline-flex items-center py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none max-w-32 rounded-lg border border-gray-200 hover:bg-slate-300  focus:z-10 focus:ring-4 focus:ring-gray-100 bg-slate-200"
          [ngClass]="{'border-main': currentLanguage === 'fr'}">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 me-2" viewBox="0 0 32 32">
            <path fill="#fff" d="M10 4H22V28H10z"></path>
            <path d="M5,4h6V28H5c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z" fill="#092050"></path>
            <path d="M25,4h6V28h-6c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z" transform="rotate(180 26 16)"
              fill="#be2a2c"></path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"></path>
            <path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff" opacity=".2"></path>
          </svg>

          {{"LANGUAGES.fr" | translate}}</button>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<footer class="z-0 w-full rounded-md mx-auto pt-6 bg-slate-100">
  <div class="mx-auto max-w-6xl py-6 sm:px-6 lg:px-8 z-0 rounded-md">
    <div class="bg-transparent rounded-md p-8">
      <div class="max-w-6xl my-auto bg-transparent">
        <form class="pb-6 md:pb-2 justify-start text-slate-600 flex items-center">
          {{"LANGUAGES.LANGUAGE" | translate}}
          <button (click)="openLanguageModal()" type="button" data-modal-target="default-modal"
            data-modal-toggle="default-modal"
            class="rounded-full bg-transparent px-2.5 text-sm font-semibold text-slate-600 ">
            {{"LANGUAGES." + currentLanguage | translate}}
          </button>
        </form>
      </div>
      <a href="https://appsaya.com" target="_blank">
        <img #footerImg class="h-auto mx-auto w-24 z-10 transition duration-300 transform hover:scale-100"
          src="../../../assets/logo/gray-logo.png" alt="Footer Image" (mouseover)="changeImage(footerImg)"
          (mouseout)="resetImage(footerImg)">
      </a>
    </div>
  </div>
</footer>
