import { Injectable } from '@angular/core';
import { Setting } from '../models/setting.interface';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {

  private _settings: Setting = {
    dateAPIFormat: "yyyy-MM-dd'T'HH:mm", 
    maxImageSizeMo: 17, 
    maxTag: 9,
    url: '/api'
  };

  constructor() {
  }

  get settings() {
    return this._settings;
  }
}