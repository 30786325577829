import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationComponent } from './registration.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { SharedModule } from '../shared/shared.module';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { RegistrationErrorComponent } from './registration-error/registration-error.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    RegistrationComponent,
    RegistrationPageComponent,
    RegistrationSuccessComponent,
    RegistrationErrorComponent
  ],
  imports: [
    CommonModule,
    DynamicFormModule,
    SharedModule,
    RouterModule,
    TranslateModule
  ]
})
export class RegistrationModule { }
