import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationComponent } from './notification/notification.component';


export enum NotificationType {
  Success,
  Warning,
  Info,
  Error
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastrService: ToastrService
  ) { }

  notify(title: string, message: string, notificationType: NotificationType, positionx?: string, positiony?: string) {
    positionx = positionx || 'items-start';
    positiony = positiony || 'items-end';

    switch (notificationType) {
      case NotificationType.Success:
        this.toastrService.success(message, title, { toastComponent: NotificationComponent, toastClass: '', payload: { 'positionx': positionx, 'positiony': positiony } });
        break;
      case NotificationType.Warning:
        this.toastrService.warning(message, title, { toastComponent: NotificationComponent, toastClass: '', payload: { 'positionx': 'items-start', 'positiony': 'items-end' } });
        break;
      case NotificationType.Info:
        this.toastrService.info(message, title, { toastComponent: NotificationComponent, toastClass: '', payload: { 'positionx': 'items-start', 'positiony': 'items-end' } });
        break;
      case NotificationType.Error:
        this.toastrService.error(message, title, { toastComponent: NotificationComponent, toastClass: '', payload: { 'positionx': 'items-start', 'positiony': 'items-end' } });
        break;
      default:
        this.toastrService.info(message, title, { toastComponent: NotificationComponent, toastClass: '', payload: { 'positionx': 'items-start', 'positiony': 'items-end' } });
        break;
    }
  }

}
