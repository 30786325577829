import { HttpBackend, HttpClient } from '@angular/common/http';
import { ChatKitty } from 'chatkitty-platform-sdk'
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatKittySdkService {
  chatkitty: ChatKitty;
  httpClient: HttpClient;

  constructor(
    private httpBackenEnd: HttpBackend,
  ) {
    this.httpClient = new HttpClient(this.httpBackenEnd);
  }

  async init() {
    this.chatkitty = new ChatKitty({
      authorizationBasePath: environment.chatKitty.authorizationBasePath,
      basePath: environment.chatKitty.basePath,
      clientId: environment.chatKitty.clientId,
      clientSecret: environment.chatKitty.clientSecret,
    });

    return await this.chatkitty.Application.retrieveAuthenticatedApplication();
  }
}
