import { Injectable } from '@angular/core';
import { utcToZonedTime } from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  constructor() { }

  convertDateToZone(date: string, zone: string):Date {
    return utcToZonedTime(date, zone);
  }
}
