<div class="min-h-full bg-gray-100">
  <nav class="bg-white shadow border-b">
    <div class="mx-auto max-w-full px-4 sm:px-6 lg:px-28">
      <div class="flex h-16 justify-between items-center">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            @if (event ) {
            <img class="block h-8 w-auto lg:hidden" [src]="event?.theme?.logo?.name | image | async"
              [alt]="event?.name">
            <img class="hidden h-8 w-auto lg:block" [src]="event?.theme?.logo?.name | image | async"
              [alt]="event?.name">
            }
          </div>
        </div>
        <div class="sm:ml-6 sm:flex sm:items-center">
          <div class="relative ml-3">
            <div class="inline-flex justify-end items-center">
              <span class="text-xs text-slate-600">{{"EVENT_PAGE.ALREADY_REGISTERED" | translate}}</span>
              <button [routerLink]="'/'" type="button"
                class="ml-2.5 flex items-center justify-center rounded-full w-24 md:w-32 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
                  stroke="currentColor" class="w-3 h-3 mr-0.5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
                {{"EVENT_PAGE.LOGIN" | translate}}
              </button>

              <!-- <div class="hs-dropdown relative inline-flex">
                <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" (click)="toggleDropdown()">
                  Actions
                  <svg [class]="isDropdownOpen ? 'hs-dropdown-open:rotate-180 size-4' : 'size-4'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
                </button>

                <div *ngIf="isDropdownOpen" class="hs-dropdown-menu transition-[opacity,margin] bg-white shadow-md rounded-lg p-2 mt-2 origin-top-left absolute left-0 top-full z-10">
                  <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100" href="#">English</a>
                  <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100" href="#">Purchases</a>
                </div>
              </div> -->
              <!--
              <form class="max-w-sm p-5 mx-auto my-auto flex items-center">
                <select id="countries"
                  class="my-auto w-24 border-none rounded-full font-bold px-2.5 py-1.5 outline-white text-sm hover:main/70 block p-2.5 bg-main border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                  (change)="switchLanguage($event.target.value)">
                  <option value="en"> English</option>
                  <option value="fr">Français</option>
                </select>
              </form> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>


  <main *ngIf="event" [ngStyle]="{'background-image': 'url(api/uploads/' + event?.theme?.background?.name  + ')'}"
    class="bg-no-repeat bg-cover bg-left-top pt-2.5 pb-36 z-50">
    <div *ngIf="!loading" class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 z-50 rounded-lg">
      <router-outlet></router-outlet>
    </div>
  </main>
  <div *ngIf="loading" class="mx-auto flex justify-center max-w-7xl py-6 sm:px-6 lg:px-8">
    <p class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm transition ease-in-out duration-150 cursor-not-allowed"
      disabled="">
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-slate-700" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
        </path>
      </svg>
      {{"EVENT_PAGE.LOADING" | translate}}
    </p>
  </div>


  <!--
  <footer class="bg-gray-900" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8">
          <img class="h-7" src="assets/logo/logo.png" alt="Company name">
          <p class="text-sm leading-6 text-gray-300">Making the world a better place through constructing elegant
            hierarchies.</p>
          <div class="flex space-x-6">
            <a href="#" target="_blank" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">Facebook</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd" />
              </svg>
            </a>
            <a href="#" target="_blank" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">Instagram</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd" />
              </svg>
            </a>
            <a href="#" target="_blank" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">X</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
              </svg>
            </a>
            <a href="#" target="_blank" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">GitHub</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clip-rule="evenodd" />
              </svg>
            </a>
            <a href="#" target="_blank" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">YouTube</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                  clip-rule="evenodd" />
              </svg>
            </a>
          </div>
        </div>
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">Solutions</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Marketing</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Analytics</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Commerce</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Insights</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">Support</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Pricing</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Documentation</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Guides</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">API Status</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">Company</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">About</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Blog</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Jobs</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Press</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Partners</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Claim</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Privacy</a>
                </li>
                <li>
                  <a href="#" target="_blank" class="text-sm leading-6 text-gray-300 hover:text-white">Terms</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
        <p class="text-xs leading-5 text-gray-400">&copy; 2020 Your Company, Inc. All rights reserved.</p>
      </div>
    </div>
  </footer>
-->
</div>
