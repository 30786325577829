<div class="relative bg-white z-50">
  <div class="mx-auto max-w-96 w-full">
    <div class="px-6 pb-14 pt-5 sm:pb-12 ">
      <div class="mx-auto max-w-4xl lg:mx-0">
        <h1 class="mt-5 mb-5 text-4xl font-bold tracking-tight text-gray-900 sm:mt-5 sm:text-4xl text-center">
          {{"REGISTRATION_MODALS.REGISTRATION_ERROR" | translate}}</h1>
        <div class="justify-center w-full">
          <svg class="mx-auto" width="219" height="217" viewBox="0 0 219 217" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M109.5 217C169.975 217 219 168.423 219 108.5C219 48.5771 169.975 0 109.5 0C49.0248 0 0 48.5771 0 108.5C0 168.423 49.0248 217 109.5 217Z"
              fill="url(#paint0_linear_413_7240)" />
            <path
              d="M175.201 217H43.8008V76.673C49.9941 76.6661 55.9318 74.2253 60.3111 69.886C64.6905 65.5466 67.1538 59.6631 67.1608 53.5264H151.841C151.834 56.5664 152.436 59.5774 153.612 62.3854C154.787 65.1934 156.513 67.7425 158.69 69.8852C160.852 72.0424 163.425 73.7531 166.259 74.9182C169.093 76.0833 172.132 76.6798 175.201 76.673V217Z"
              fill="white" />
            <path
              d="M109.501 147.56C128.853 147.56 144.541 132.015 144.541 112.84C144.541 93.6648 128.853 78.1201 109.501 78.1201C90.1489 78.1201 74.4609 93.6648 74.4609 112.84C74.4609 132.015 90.1489 147.56 109.501 147.56Z"
              fill="#4285F4" />
            <path
              d="M121.889 129.208L109.5 116.933L97.1115 129.208L92.982 125.116L105.37 112.841L92.982 100.565L97.1115 96.4736L109.5 108.749L121.889 96.4736L126.018 100.565L113.63 112.841L126.018 125.116L121.889 129.208Z"
              fill="white" />
            <path
              d="M128.481 156.24H90.5206C88.1016 156.24 86.1406 158.183 86.1406 160.58C86.1406 162.977 88.1016 164.92 90.5206 164.92H128.481C130.9 164.92 132.861 162.977 132.861 160.58C132.861 158.183 130.9 156.24 128.481 156.24Z"
              fill="#DFEAFB" />
            <path
              d="M141.62 173.6H77.38C74.961 173.6 73 175.543 73 177.94C73 180.337 74.961 182.28 77.38 182.28H141.62C144.039 182.28 146 180.337 146 177.94C146 175.543 144.039 173.6 141.62 173.6Z"
              fill="#DFEAFB" />
            <ellipse cx="109.5" cy="113" rx="35.5" ry="35" fill="#FB6B6B" />
            <path
              d="M94.5104 98.5104C94.777 98.2433 95.0937 98.0315 95.4423 97.8869C95.7909 97.7424 96.1646 97.668 96.542 97.668C96.9194 97.668 97.2931 97.7424 97.6417 97.8869C97.9903 98.0315 98.307 98.2433 98.5737 98.5104L109 108.933L119.427 98.5104C119.694 98.2436 120.011 98.0319 120.359 97.8875C120.708 97.7431 121.081 97.6688 121.459 97.6688C121.836 97.6688 122.21 97.7431 122.558 97.8875C122.907 98.0319 123.224 98.2436 123.49 98.5104C123.757 98.7772 123.969 99.0939 124.113 99.4425C124.258 99.7911 124.332 100.165 124.332 100.542C124.332 100.919 124.258 101.293 124.113 101.642C123.969 101.99 123.757 102.307 123.49 102.574L113.068 113L123.49 123.427C124.029 123.966 124.332 124.697 124.332 125.459C124.332 126.221 124.029 126.952 123.49 127.49C122.952 128.029 122.221 128.332 121.459 128.332C120.697 128.332 119.966 128.029 119.427 127.49L109 117.068L98.5737 127.49C98.0349 128.029 97.3041 128.332 96.542 128.332C95.78 128.332 95.0492 128.029 94.5104 127.49C93.9715 126.952 93.6688 126.221 93.6688 125.459C93.6688 124.697 93.9715 123.966 94.5104 123.427L104.933 113L94.5104 102.574C94.2433 102.307 94.0315 101.99 93.8869 101.642C93.7424 101.293 93.668 100.919 93.668 100.542C93.668 100.165 93.7424 99.7909 93.8869 99.4423C94.0315 99.0937 94.2433 98.777 94.5104 98.5104Z"
              fill="white" />
            <defs>
              <linearGradient id="paint0_linear_413_7240" x1="109.5" y1="0" x2="109.5" y2="217"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#E3ECFA" />
                <stop offset="1" stop-color="#DAE7FF" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          <b class="font-bold text-xl">{{"REGISTRATION_MODALS.OOPS" | translate}}</b>
          <br><br>
          {{"REGISTRATION_MODALS.THERE_WAS_AN_ISSUE" | translate}}
          <br><br>
          {{"REGISTRATION_MODALS.PLEASE_DOUBLE_CHECK" | translate}}
        </p>
        <div class="mt-10 flex justify-center"> 
          <a  (click)="closeModal()"
            class="hover:cursor-pointer rounded-md bg-main px-5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
            {{"REGISTRATION_MODALS.GO_BACK" | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>