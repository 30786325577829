import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss']
})
export class RegistrationErrorComponent {

  @Output('closeModal') close: EventEmitter<boolean> = new EventEmitter(false);

  closeModal(){
    this.close.emit(true);
  }

}
