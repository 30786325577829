import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CustomFieldHelperService } from './custom-field-helper.service';
import { CustomInputService } from './custom-input.service';

@Injectable({
  providedIn: 'root',
})
export class FieldBuilderService {

  constructor(
    private customInputService: CustomInputService,
    private customFieldHelper: CustomFieldHelperService) { }

  newField(formFields: FormlyFieldConfig[], model, field: any, stepIndex: number) {
    switch (field.type) {
      case 'custom-input':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyCustomInput({ key: field.key, label: field.label, props: field.props })
        );
        break;
      case 'formly-custom-textarea':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyCustomTextArea({ key: field.key, label: field.label, props: field.props })
        );
        break;
      case 'custom-radio-type':
        let index = this.getFieldIndex(formFields, field.key, stepIndex);
        const newInput = this.customInputService.formlyCustomRadio({ key: field.key, label: field.label, props: field.props });
        if (index == -1) {
          formFields[0].fieldGroup[stepIndex].fieldGroup.push(newInput);
        } else {
          formFields[0].fieldGroup[stepIndex].fieldGroup[index] = newInput;
        }
        break;
      case 'custom-checkbox-type':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyCustomCheckbox({ key: field.key, label: field.label, props: field.props })
        );
        break;
      case 'formly-country-input':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyCountryInput({ key: field.key, label: field.label, templateOptions: {} })
        );
        break;
      case 'formly-industry-input':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyIndustryInput({ key: field.key, label: field.label, templateOptions: field.props })
        );
        break;
      case 'formly-offer-input':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyOfferInput({
            key: field.key, label: field.label, model: model, templateOptions: field.props, expressions: {
              'props.disabled': '!model.myIndustry',
            }
          })
        );
        break;
      case 'formly-interests-input':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyInterestsInput({ key: field.key, label: field.label, templateOptions: field.props })
        );
        break;
      case 'formly-occupation-input':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyOccupationInput({ key: field.key, label: field.label, templateOptions: field.props })
        );
        break;
      /* case 'formly-lookingfor-input':
         formFields[0].fieldGroup[stepIndex].fieldGroup.push(
           this.customInputService.formlyLookingFor({ key: field.key, label: field.label, templateOptions: field.props })
         );
         break;*/
      case 'custom-unique-select':
        formFields[0].fieldGroup[stepIndex].fieldGroup.push(
          this.customInputService.formlyCustumDropDownSelect({ key: field.key, label: field.label, options: field.props.options, templateOptions: field.props })
        );
        break;
    }
  }

  attacheNewField(formFields: FormlyFieldConfig[], model: any, type: string, stepIndex: number) {
    const field = this.customFieldHelper.convertModelToField(model, type);
    this.newField(formFields, model, field, stepIndex);
    return field;
  }

  getFieldIndex(formFields: FormlyFieldConfig[], fieldKey: string, stepIndex: number) {
    return formFields[0].fieldGroup[stepIndex].fieldGroup.findIndex(field => field.key == fieldKey);
  }

  confirmationStep() {
    return this.customInputService.confirmationStep({
      label: 'Confirmation',
      templateOptions: {},
      expressions: {}
    });
  }
}
