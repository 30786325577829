import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ImagePipe } from './pipes/image.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NotificationComponent } from './components/notifications/notification/notification.component';
import { QRCodePipe } from './pipes/qrcode.pipe';
import { ChatComponent } from './components/chat/chat.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ImagePipe,
    QRCodePipe,
    NotificationComponent,
    ChatComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule
  ],
  exports: [
    HttpClientModule,
    ImagePipe,
    QRCodePipe,
    FormsModule,
    ReactiveFormsModule,
    NotificationComponent,
    ChatComponent
  ]
})
export class SharedModule { }
