import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { CountryService } from 'src/app/shared/services/country.service';
import { IndustryService } from 'src/app/shared/services/industry.service';

@Component({
  selector: 'formly-confirmation',
  templateUrl: './formly-confirmation.component.html',
  styleUrls: ['./formly-confirmation.component.scss']
})
export class FormlyConfirmationComponent extends FieldType<FieldTypeConfig> {

  constructor(
    private countryService: CountryService,
    private industryService: IndustryService
  ) {
    super();
  }

  get user() {
    return this.model;
  }

  get country() {
    const countryId: string = this.user.country;
    return this.countryService.getById(countryId).name;
  }

  get myIndustry() {
    const industryId: string = this.user.myIndustry;
    return this.industryService.getById(industryId).name;
  }

  get myOffer() {
    const offersId: string[] = this.user.myOffer;
    let myOffer: string[] = [];
    offersId.map(id => myOffer.push(this.industryService.getById(id).name))
    return myOffer.join(', ')
  }

  get myInterests() {
    const myInterestsId: string[] = this.user.myInterests;
    let myInterests: string[] = [];
    myInterestsId.map(id => myInterests.push(this.industryService.getById(id).name))
    return myInterests.join(', ');
  }

  get wantedDeals() {
    const wantedDeals: string[] = this.user.lookingFor;
    return wantedDeals.join(', ');
  }
}
