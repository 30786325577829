import { Injectable } from '@angular/core';
import { Event } from '../models/event/event.model';
import { AngularIcsGeneratorService } from 'angular-ics-generator';
import { DatetimeService } from './datetime.service';

@Injectable({
  providedIn: 'root'
})
export class IcsService {

  constructor(
    private icsService: AngularIcsGeneratorService,
    private datetimeService: DatetimeService,
  ) { }

  calendarForEvent(event: Event, url: string) {
    const from = event?.schedule?.from;
    const to = event?.schedule?.to;
    const zone = event?.schedule?.timeZone.zone;
    const location = event?.location?.country?.name + ' ' + event?.location?.state + ' ' + event?.location?.city;

    this.icsService.downloadIcs(
      this.datetimeService.convertDateToZone(from, zone),
      this.datetimeService.convertDateToZone(to, zone),
      event.name,
      event.description,
      url,
      location,
      event.name,
      'EN',
      '35');
  }

}
