import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any'
})
export class UploadUtilsService {
  constructor() { }

  /**
   * Before uploding file 
   * @param selectedFile selected file of type File
   * @param maxSize the maximum size supported in MB
   * @param acceptedFormat array of formats allowed to upload
   * @returns Boolean
   */
  beforeUpload(selectedFile: File, maxSize: number, acceptedFormat: string[]): boolean {

    const file: File = selectedFile;

    try {
      this.checkFile(file);
      this.checkSize(file.size, maxSize);
      this.checkType(file.type, acceptedFormat);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('Check failed');
    }

    return true
  }

  /**
   * check the size of the file
   * @param fileSize file size bytes
   * @param maxSize the maximum size supported in MB
   */
  private checkSize(fileSize: number, maxSize: number) {
    const fileSizeMB = fileSize / 1024 / 1024;
    if (fileSizeMB > maxSize) {
      console.error(`The file size exceeds ${maxSize} MB!`);
      throw new Error(`The file size exceeds ${maxSize} MB!`)
    }
  }


  /**
  * check the type of the file
  * @param fileType file type as string
  * @param allowedTypes all allowed file to upload array of string
  */
  private checkType(fileType: string, allowedTypes: string[]) {
    if (!allowedTypes.includes(fileType)) {
      console.error('The format of the selected file is not supported!');
      throw new Error('The format of the selected file is not supported.')
    }
  }

  /**
  * check the type of the file
  * @param file File object 
  */
  private checkFile(file: File) {
    if (!file) {
      console.error('Please select a file!');
      throw new Error('No file have been selected.');
    }
  }
}
