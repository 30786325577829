import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { FieldBuilderService } from './field-builder.service';
import { SchemaBuilderHelper } from './schema-builder-helper.service';
import { SchemaSkeletonService } from './schema-skeleton.service';

@Injectable({
  providedIn: 'root',
})
export class SchemaBuilderService {
  private schemaPath: string = '/assets/forms/default_form_template.json';

  private _model: any = {};
  formFields: BehaviorSubject<FormlyFieldConfig[]> = new BehaviorSubject([]);
  schema: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private schemaBuilderHelper: SchemaBuilderHelper,
    private schemaSkeletonService: SchemaSkeletonService,
    private fieldBuilder: FieldBuilderService,
    private httpClient: HttpClient,
  ) {}

  loadSchema() {
    this.loadSkeleton();
    return this.loadTemplate();
  }

  private loadSkeleton() {
    this.formFields.next(this.schemaSkeletonService.skeleton);
  }

  private loadTemplate() {
    return this.httpClient.get(this.schemaPath)
      .subscribe({
        next: (schema: any) => {
          this._model = schema.model;
          this.build(schema);
        }
      })
  }

  updateFormFields(formlyFieldConfig: FormlyFieldConfig[]) {
    this.formFields.next(formlyFieldConfig);
    this.schema.next(this.schema.value);
  }

  build(schema) {
    this.reset();

    this._model = schema.model;
    schema.step1.fields.map(field => {
      this.fieldBuilder.newField(this.getFormFields(), this._model, field, 0);
    });
    schema.step2.fields.map(field => {
      this.fieldBuilder.newField(this.getFormFields(), this._model, field, 1);
    });
    schema.step3.fields.map(field => {
      this.fieldBuilder.newField(this.getFormFields(), this._model, field, 2);
    });

    this.schema.next(schema);
    return this.formFields.value;
  }

  updateSchema(formFields, field, stepIndex) {
    let schemaUpdates = this.getSchema();
    if (stepIndex == 0) {
      schemaUpdates.step1.fields.push(field);
    }

    if (stepIndex == 1) {
      schemaUpdates.step2.fields.push(field);
    }

    if (stepIndex == 2) {
      schemaUpdates.step3.fields.push(field);
    }

    this.schema.next(schemaUpdates);
    this._model[field.key] = '';
    this.formFields.next(formFields);
  }

  reset() {
    this._model = {};
    this.schemaBuilderHelper.currentStepperIndex = 0;
    this.formFields.next(JSON.parse(this.schemaSkeletonService.baseSkeleton));
  }

  isSchemaField(keyField: string){
    return this.schemaBuilderHelper.isSchemaField(this.getFormFields(), keyField);
  }

  orderUp(fieldkey: string) {
    const result = this.schemaBuilderHelper.updateOrderUp(this.getSchema(), this.getFormFields(), fieldkey);
    this.formFields.next(result.fields);
    this.schema.next(result.schema);
  }

  orderDown(fieldkey: string) {
    const result = this.schemaBuilderHelper.updateOrderDown(this.getSchema(), this.getFormFields(), fieldkey);
    this.formFields.next(result.fields);
    this.schema.next(result.schema);
  }

  removeField(fielKey: string) {
    const result = this.schemaBuilderHelper.removeField(this.getSchema(), this.getFormFields(), fielKey);
    this.schema.next(result.schema);
    this.formFields.next(result.fields);
  }

  getSchema() {
    return this.schema.value;
  }

  getModel() {
    return this._model;
  }

  getFormFields() {
    return this.formFields.value;
  }

}
