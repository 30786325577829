<div class="relative bg-white z-50">
  <div class="mx-auto max-w-96 min-w-52">
    <div class="px-6 pb-14 pt-5 sm:pb-20 ">
      <div class="mx-auto max-w-4xl lg:mx-0">
        <h1 class="mt-5 mb-5 text-4xl font-bold tracking-tight text-gray-900 sm:mt-5 sm:text-4xl text-center">
          {{"REGISTRATION_MODALS.REGISTRATION_COMPLETE" | translate}}</h1>
        <div class="justify-center w-full">
          <svg class="mx-auto" width="219" height="217" viewBox="0 0 219 217" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M109.5 217C169.975 217 219 168.423 219 108.5C219 48.5771 169.975 0 109.5 0C49.0248 0 0 48.5771 0 108.5C0 168.423 49.0248 217 109.5 217Z"
              fill="url(#paint0_linear_412_7201)" />
            <path
              d="M175.201 217H43.8008V76.673C49.9941 76.6661 55.9318 74.2253 60.3111 69.886C64.6905 65.5466 67.1538 59.6631 67.1608 53.5264H151.841C151.834 56.5664 152.436 59.5774 153.612 62.3854C154.787 65.1934 156.513 67.7425 158.69 69.8852C160.852 72.0424 163.425 73.7531 166.259 74.9182C169.093 76.0833 172.132 76.6798 175.201 76.673V217Z"
              fill="white" />
            <path
              d="M109.501 147.56C128.853 147.56 144.541 132.015 144.541 112.84C144.541 93.6648 128.853 78.1201 109.501 78.1201C90.1489 78.1201 74.4609 93.6648 74.4609 112.84C74.4609 132.015 90.1489 147.56 109.501 147.56Z"
              fill="#4285F4" />
            <path
              d="M121.889 129.208L109.5 116.933L97.1115 129.208L92.982 125.116L105.37 112.841L92.982 100.565L97.1115 96.4736L109.5 108.749L121.889 96.4736L126.018 100.565L113.63 112.841L126.018 125.116L121.889 129.208Z"
              fill="white" />
            <path
              d="M128.481 156.24H90.5206C88.1016 156.24 86.1406 158.183 86.1406 160.58C86.1406 162.977 88.1016 164.92 90.5206 164.92H128.481C130.9 164.92 132.861 162.977 132.861 160.58C132.861 158.183 130.9 156.24 128.481 156.24Z"
              fill="#DFEAFB" />
            <path
              d="M141.62 173.6H77.38C74.961 173.6 73 175.543 73 177.94C73 180.337 74.961 182.28 77.38 182.28H141.62C144.039 182.28 146 180.337 146 177.94C146 175.543 144.039 173.6 141.62 173.6Z"
              fill="#DFEAFB" />
            <ellipse cx="109.5" cy="113" rx="35.5" ry="35" fill="#97D78C" />
            <path
              d="M134.489 102.608L104.489 130.608C104.228 130.852 103.917 131.046 103.576 131.179C103.234 131.311 102.867 131.38 102.497 131.38C102.127 131.38 101.76 131.311 101.419 131.179C101.077 131.046 100.766 130.852 100.505 130.608L87.3799 118.358C87.1183 118.113 86.9107 117.824 86.7691 117.505C86.6276 117.185 86.5547 116.844 86.5547 116.498C86.5547 116.153 86.6276 115.811 86.7691 115.492C86.9107 115.173 87.1183 114.883 87.3799 114.639C87.6415 114.395 87.9521 114.201 88.2939 114.069C88.6357 113.937 89.0021 113.869 89.3721 113.869C89.742 113.869 90.1084 113.937 90.4502 114.069C90.7921 114.201 91.1026 114.395 91.3643 114.639L102.499 125.032L130.51 98.8932C131.038 98.4001 131.755 98.123 132.502 98.123C133.249 98.123 133.966 98.4001 134.494 98.8932C135.022 99.3864 135.319 100.055 135.319 100.753C135.319 101.45 135.022 102.119 134.494 102.612L134.489 102.608Z"
              fill="white" />
            <defs>
              <linearGradient id="paint0_linear_412_7201" x1="109.5" y1="0" x2="109.5" y2="217"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#E3ECFA" />
                <stop offset="1" stop-color="#DAE7FF" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          {{"REGISTRATION_MODALS.YOUR_REGISTRATION_HAS_BEEN" | translate}}
          <b> {{"REGISTRATION_MODALS.SUCCESFULLY_COMPLETED" | translate}}</b>
          <br><br>
          {{"REGISTRATION_MODALS.STAY_TUNED_FOR_EMAIL" | translate}}
          <br><br>
          {{"REGISTRATION_MODALS.THANKS_FOR_REGISTERING" | translate}}
        </p>
        <div class="mt-10 flex items-center gap-x-6">
          <a [href]="successUrl"
            class="rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
            {{"REGISTRATION_MODALS.THANK_YOU" | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>