import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';
import { AppSettingService } from '../services/app-setting.service';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private appSettings: AppSettingService
  ) { }


  transform(url: string): Observable<SafeUrl> {
    const endpoint: string = `${this.appSettings.settings.url}/uploads/${url}`;
    return this.httpClient
      .get(endpoint, { responseType: 'blob' })
      .pipe(
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
      )
  }

}
