import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { ItemSelect } from 'src/app/dynamic-form/custom/models/select-item.model';


export interface RawOccupation{
  name: string,
  parent: string,
}

@Injectable({
  providedIn: 'root'
})
export class OccupationService {
  private url: string = 'assets/files/occupations.csv';

  rawOccupations: RawOccupation[] = [];
  occupations: ItemSelect[] = [];

  constructor(
    private httpClient: HttpClient,
  ) {   }


  getOccupations(){
    if(this.occupations.length > 0){
      return of(this.occupations);
    }

    const api = this.httpClient.get(this.url, {responseType: 'text'});

    return api
    .pipe(
      map(data => {
        this.parseCSV(data);
        return this.occupations;
      })
    );
  }

  parseCSV(data: any){
    this.rawOccupations = [];
    let csvToRowArray = data.split("\n");
    for (let index = 1; index < csvToRowArray.length - 1; index++) {
      let row = csvToRowArray[index].split(",");
      this.rawOccupations.push({
        name: row[0],
        parent: row[1].replace('\r', '')
      });
    }
    this.parseOccupation();
  }

  parseOccupation(){
    this.occupations = [];
    this.rawOccupations.map(raw => {
      if(!raw.parent){
        const indexParent  = this.occupations.findIndex(occupation => occupation.label == raw.name);
        if(indexParent == -1) this.occupations.push({id: raw.name, label: raw.name, subElements: []});
      } else {
        this.occupations.map(value => {
          if(value.label == raw.parent){
            value.subElements.push({id: raw.name, label: raw.name, subElements: []});
          }
        });
      }
    });
  }

  filterOccupationsByKeywordOnGroup(keyword: string): Observable<any> {
    const results: ItemSelect[] = [];
    this.occupations.map(occupation => {
      if (occupation.label.toLowerCase().includes(keyword)) {
        results.push(occupation);
      } else {
        const subResults = occupation.subElements.filter(subInd => subInd.label.toLowerCase().includes(keyword.toLowerCase()));
        if (subResults.length > 0) {
          results.push({
            ...occupation,
            subElements: subResults,
          })
        }
      }
    });
    return of(results);
  }


}


