<div *ngIf="loaded">
  <img class="h-2/3 w-full object-cover rounded-lg shadow-sm" [src]="event.theme.banner.name | image | async" alt="">
</div>
<div class="grid grid-cols-1 md:grid-cols-3 min-h-full  bg-gray-50 rounded-lg z-20 mt-10">
  <div class="flex flex-col justify-start px-7 py-9 bg-gray-50 z-20 rounded-lg">
    <div class="">
      <div>
        <div *ngIf="!loaded" class="flex items-center justify-center space-x-2 m-16">
          <div role="status">
            <svg aria-hidden="true" class="inline w-12 h-12 text-gray-200 animate-spin fill-main2" viewBox="0 0 100 101"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-6xl mt-4 py-2">
        <div class="">
          <div class="flex justify-start items-start gap-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-main">
              <path
                d="M12 11.993a.75.75 0 0 0-.75.75v.006c0 .414.336.75.75.75h.006a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75H12ZM12 16.494a.75.75 0 0 0-.75.75v.005c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H12ZM8.999 17.244a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.006ZM7.499 16.494a.75.75 0 0 0-.75.75v.005c0 .414.336.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H7.5ZM13.499 14.997a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.005a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.005ZM14.25 16.494a.75.75 0 0 0-.75.75v.006c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75h-.005ZM15.75 14.995a.75.75 0 0 1 .75-.75h.005a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75H16.5a.75.75 0 0 1-.75-.75v-.006ZM13.498 12.743a.75.75 0 0 1 .75-.75h2.25a.75.75 0 1 1 0 1.5h-2.25a.75.75 0 0 1-.75-.75ZM6.748 14.993a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z" />
              <path fill-rule="evenodd"
                d="M18 2.993a.75.75 0 0 0-1.5 0v1.5h-9V2.994a.75.75 0 1 0-1.5 0v1.497h-.752a3 3 0 0 0-3 3v11.252a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V7.492a3 3 0 0 0-3-3H18V2.993ZM3.748 18.743v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5h-13.5a1.5 1.5 0 0 1-1.5-1.5Z"
                clip-rule="evenodd" />
            </svg>
            <div>
              <p class="text-base text-slate-700">
                @if(!isEventOneDay()){
                {{ event?.schedule?.from | date: 'dd ' }} - {{ event?.schedule?.to | date: 'dd '}}
                {{ 'MONTHS.' + (event?.schedule?.from | date: 'MMMM') | translate }}
                {{event?.schedule?.from | date: 'y' }}
                } @else {
                {{ event?.schedule?.from | date: 'dd ' }} {{ 'MONTHS.' + (event?.schedule?.from | date: 'MMMM') |
                translate
                }}
                {{event?.schedule?.from | date: 'y' }}
                }
              </p>
              <p class="text-base text-slate-700">
                {{ event?.schedule?.from | date: 'shortTime':event?.schedule?.timeZone?.offset }} - {{
                event?.schedule?.to | date: 'shortTime':event?.schedule?.timeZone?.offset }}
                (UTC {{event?.schedule?.timeZone?.offset == 'Z' ? '0' : event?.schedule?.timeZone?.offset}})
              </p>
            </div>
          </div>

          <div class="mt-7 flex justify-start items-start gap-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-main">
              <path fill-rule="evenodd"
                d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                clip-rule="evenodd" />
            </svg>
            <div>
              <p class="text-base text-slate-700">
                {{ event?.location?.address }} {{ event.location.city }}, <span *ngIf="event?.location?.state">{{
                  event.location.state
                  }},</span> {{ event?.location?.country?.name }}
              </p>
            </div>
          </div>

          <div class="mt-7">
            <pre class="text-base text-justify font-text whitespace-pre-wrap break-words line-clamp-3 text-slate-900 leading-7">{{ event.description }}</pre>
            <a (click)="toggleDetails()" class="text-sm text-blue-600 hover:text-blue-400 underline cursor-pointer">
              read more
            </a>
            <p class="mt-7 text-xs text-gray-700">
              <span
                class="mr-1.5 mb-0.5 inline-flex items-center rounded-md bg-gray-300 px-2 py-1 text-xs font-medium text-gray-600">
                {{ event.type }}
              </span>
              <span
                class="mr-1.5 mb-0.5 inline-flex items-center rounded-md bg-gray-300 px-2 py-1 text-xs font-medium text-gray-600">
                {{ event.nature }}
              </span>
              <span *ngFor="let tag of event?.tags"
                class="mr-1.5 mb-1.5 inline-flex items-center rounded-md bg-gray-300 px-2 py-1 text-xs font-medium text-gray-600">
                {{ tag.label }}
              </span>
            </p>
          </div>

          <div class="mt-5 ">
            <div class="md:hidden flex justify-center">
              <div
                class="animate-bounce bg-white dark:bg-slate-800 p-2 w-10 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center">
                <svg class="w-6 h-6 text-main" fill="none" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-2 flex flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-2 bg-gray-50 z-20 rounded-lg">
    <div class="mx-auto w-full md:w-3/4">
      <div class="flex justify-start items-center gap-x-3">
        <h2 class="text-2xl font-bold leading-9 tracking-tight text-gray-900">{{ event.name }}</h2>
      </div>

      <div *ngIf="ready" class="mt-10">
        <div>
          <form [formGroup]="formDefinition" (submit)="onSubmit()">
            <formly-form [model]="model" [fields]="fields" [form]="formDefinition">
            </formly-form>
          </form>
        </div>

        <div class="mt-10">
          <div class="relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200"></div>
            </div>
            <div class="relative flex justify-center text-sm font-medium leading-6">
              <!--  <span class="bg-white px-6 text-gray-900">Or continue with</span> -->
            </div>
          </div>

          <div class="mt-6 grid grid-cols-2 gap-4">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div @opacity *ngIf="isModal" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed z-40 inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>

  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:p-0">
      <div @opacityTranslateY *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all duration-75 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 z-50">
        <div>
          <div *ngIf="saving" class="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
            <svg class="animate-spin h-10 w-10 text-slate-500" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
              </circle>
              <path class="opacity-50" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
          </div>
          <div *ngIf="saving" class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Saving...</h3>
            <div class="mt-2">
              <!--  <p class="text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Consequatur amet labore.</p> -->
            </div>
          </div>
          <div *ngIf="savedWithSuccess" class="mt-3 text-center sm:mt-5 z-50">
            <app-registration-success></app-registration-success>
          </div>
          <div *ngIf="savedWithError" class="mt-3 text-center sm:mt-5 z-50">
            <app-registration-error (closeModal)="closeModal($event)"></app-registration-error>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
        </div>
      </div>
    </div>
  </div>
</div>





<div @opacity *ngIf="isModalDetails" class="relative z-40" aria-labelledby="modal-title" role="dialog"
  aria-modal="true">
  <div class="fixed z-40 inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>

  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex min-h-full items-start justify-center p-4 text-center sm:p-0">
      <div @opacityTranslateY *ngIf="isModalDetails"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 shadow-xl transition-all duration-75 sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 z-50">
        <div class="absolute top-2 right-3">
          <button (click)="toggleDetails()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
              class="size-6 text-blue-600 hover:text-blue-400">
              <path fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <div>
          <div class="bg-white py-3">
            <div class="mx-auto px-3">
              <div class="mx-auto flex flex-col justify-start items-center gap-x-8 gap-y-20 pb-10">
                <div class="">
                  <div class="text-base leading-7 text-gray-700 lg:max-w-lg">
                    <p class="text-base font-semibold leading-7 text-indigo-600">Description</p>
                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ event.name }}</h1>
                    <div class="max-w-xl">
                      <pre class="font-text whitespace-pre-wrap break-words mt-8 text-justify">{{ event.description }}</pre>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="relative">
                    <div class="xl:mx-auto xl:max-w-7xl xl:px-8">
                      <img [src]="event?.theme?.banner?.name | image | async" alt=""
                        class="aspect-[5/2] w-full object-cover xl:rounded-3xl">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
