<div [routerLink]="['./register', form.id]"
  class="h-48 flex flex-col border border-slate-200 rounded-md p-3 hover:bg-slate-100 hover:cursor-pointer">
  <dt class="flex items-center gap-x-3 text-base font-semibold text-slate-700 line-clamp-1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 flex-none text-main3">
      <path fill-rule="evenodd"
        d="M1 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V6Zm4 1.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2 3a4 4 0 0 0-3.665 2.395.75.75 0 0 0 .416 1A8.98 8.98 0 0 0 7 14.5a8.98 8.98 0 0 0 3.249-.604.75.75 0 0 0 .416-1.001A4.001 4.001 0 0 0 7 10.5Zm5-3.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm0 6.5a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm.75-4a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5Z"
        clip-rule="evenodd" />
    </svg>
    {{ form.name }}
  </dt>
  <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
    <p class="flex-auto line-clamp-2">{{ form.purpose }}</p>
    <p class="mt-6 flex justify-center md:justify-end">
      <button [routerLink]="['./register', form.id]" type="button"
        class="w-40 rounded-md bg-main3 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-main3/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main3">
        {{'EVENT_PAGE.REGISTER' | translate}}
      </button>
    </p>
  </dd>
</div>
