import { Country } from "../shared/country.model";
import { Industry } from "../shared/industry.model";
import { WantedDeal } from "../shared/wantedDeals.model";

export class RegisterRequest {
    public constructor(
        public id: string = '',
        public salutation: string = '',
        public firstName: string = '',
        public lastName: string = '',
        public email: string = '',
        public mobilePhone: string = '',
        public country: Country,
        public businessCity: string = '',
        public address: string = '',
        public company: string = '',
        public occupation: string = '',
        public myIndustry: Industry = new Industry(),
        public myOffers: Industry[] = [],
        public myInterests: Industry[] = [],
        public lookingFor: WantedDeal[] = [],
        public workshopIds: number[] = [],
        public eventId: string = '',
        public metaField: {},
    ) { }
}