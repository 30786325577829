export const environment = {
    managerUrl: 'https://manager-stage.welcome.appsaya.com/',
    welcomeUrl: 'https://stage-welcome.appsaya.com/',
    authConfig: {
        issuer: 'https://login-stage.welcome.appsaya.com/realms/welcome-realm',
        tokenEndpoint: 'https://login-stage.welcome.appsaya.com/realms/welcome-realm/protocol/openid-connect/token',
        requireHttps: false,
    },
    chatKitty: {
        clientId: '7f812bed-5059-4604-92ba-8ae70c2c0b7f',
        clientSecret: '06b70b76-bf68-403a-84eb-df93af16047c',
        authorizationBasePath: 'https://authorization.staging.chatkitty.com/oauth/token',
        basePath: 'https://api.staging.chatkitty.com',
        widgetId: "d574qMo1UZbT1KA7",
        environment: {
            mode: 'sandbox',
            origin: 'https://ui.staging.chatkitty.com',
            environment: 'staging',
        },
    }
};
