import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Event } from '../shared/models/event/event.model';
import { EventService } from '../shared/services/event.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  loading: boolean = false;
  eventId: string | null;
  event: Event;
  isDropdownOpen: boolean = true;


  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const event: string = this.route.snapshot.paramMap.get('event') || '';
    if (event.length > 0) {
      const splited = event.split('-');
      this.eventId = splited[splited.length - 1];
      if (this.eventId) this.getEvent(this.eventId);
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  switchLanguage(language: string) {
    this.translate.use(language); // switch language using ngx-translate
  }

  getEvent(eventId: string) {
    this.loading = true;
    this.eventService
      .getEventById(eventId)
      .subscribe({
        next: result => {
          this.event = result;
          this.loading = false;
        },
        error: e => {
          this.loading = false;
          console.log(e);
        },
      })
  }


}
