import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, catchError, tap } from 'rxjs';
import { defaultImageTypesSupported } from '../models/others/accepted-upload-formats';
import { UpdateUserRequest } from '../models/user/update-user.request';
import { User } from '../models/user/user.model';
import { AppSettingService } from './app-setting.service';
import { ErrorService } from './error.service';
import { FileUploaderService } from './file-uploader.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  session: BehaviorSubject<User> = new BehaviorSubject(new User());

  constructor(
    private oauthService: OAuthService,
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private fileUploader: FileUploaderService
  ) { }

  getCurrentSession() {
    const url: string = `${this.appSettings.settings.url}/sessions/current-session`;

    return this.httpClient
      .get<User>(url)
      .pipe(
        tap(user => this.session.next(user)),
      );
  }

  updateMainProfile(request: UpdateUserRequest) {
    const url: string = `${this.appSettings.settings.url}/profile/update-my-profile`;
    request.id = this.session.value.id;
    request.ssoId = this.session.value.ssoId;

    return this.httpClient
      .patch<User>(url, request)
      .pipe(
        tap(user => this.session.next(user)),
        catchError(error => this.errorService.handleError(error, { title: 'Update User', message: 'We could not update your profile.' }, true))
      );
  }

  updateProfilePicture(selected: any) {
    const endpoint: string = `profile/profile-picture`;
    return this.fileUploader
      .onFileSelect<User>(selected, endpoint, this.appSettings.settings.maxImageSizeMo, defaultImageTypesSupported)
      .pipe(
        tap(user => this.session.next(user)),
        catchError(error => this.errorService.handleError(error, { title: 'Update User', message: 'We could not update your profile picture.' }, true))
      );
  }

  logout() {
    this.oauthService.logOut();
  }
}
