import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser'; // Import Meta
import { Event } from 'src/app/shared/models/event/event.model';
import { Form } from 'src/app/shared/models/event/form.model';
import { EventService } from 'src/app/shared/services/event.service';
import { IcsService } from 'src/app/shared/services/ics.service';
import { environment } from 'src/environments/environment';
import { SchemaSkeletonService } from './../../dynamic-form/services/schema-skeleton.service';

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss'],
})
export class EventPageComponent implements OnInit {
  event: Event;
  pageUrl: string;
  isFormsLoading: boolean;
  forms: Form[] = [];
  loaded: boolean = false;
  socialImgUrl: string;

  constructor(
    private eventService: EventService,
    private calendarService: IcsService,
    private titleService: Title,
    private metaService: Meta,
    private schemaSkeletonService: SchemaSkeletonService
  ) { }

  ngOnInit(): void {
    this.event = this.eventService.event;
    this.isEventOneDay();
    this.pageUrl = window.location.href;
    if (this.event) {
      this.titleService.setTitle(`Registration | ${this.event.name}`);
      this.metaService.updateTag({ name: 'description', content: `Register for ${this.event.name} now!` });
      if (this.event.theme?.banner?.name != null)
        this.socialImgUrl = `${environment.welcomeUrl}api/uploads/${this.event.theme.banner.name}`;
    }
    this.loaded = true;
    this.loadForms();
  }

  addToCalendar() {
    this.calendarService.calendarForEvent(this.event, this.pageUrl);
  }

  isEventOneDay() {
    return this.eventService.isEventOneDay();
  }

  loadForms() {
    this.isFormsLoading = true;
    this.schemaSkeletonService
      .loadForms(this.event.id)
      .subscribe({
        next: forms => {
          this.isFormsLoading = false;
          this.forms = forms;
        },
        error: error => {
          console.log(error);
        }
      })
  }

  jumpToSection(section: string | null) {
    if (section) document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' })
  }

  download(url: string, originalName: string) {
    this.eventService.downloadBrochure(url, originalName);
  }
}
