import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'formly-array-type',
    template: `
    <div class="w-full mb-3">
      <legend *ngIf="props.label">{{ props.label.toUpperCase() | translate }}</legend>
      <p *ngIf="props.description">{{ props.label.toUpperCase() | translate }}</p>


      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <div *ngFor="let field of field.fieldGroup; let i = index" class="row align-items-start">
        <formly-field class="col" [field]="field"></formly-field>
        <div *ngIf="field.props.removable !== false" class="col-2 text-right">
          <button (click)="remove(i)" type="button" class="rounded-full bg-white p-1 text-slate-700 shadow-sm hover:bg-white/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </button>
        </div>
      </div>
      <div class="d-flex flex-row-reverse">
            <button (click)="add()" type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm hover:bg-white/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/65">
                More Options
                <svg class="-mr-0.5 h-5 w-5"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
            </button>
        </div>
    </div>
  `,
})
export class ArrayTypeComponent extends FieldArrayType { }
