import { Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";



@Component({
    selector: 'field-wrapper',
    template: `
    <div>
        <ng-container #fieldComponent></ng-container>
    </div>
    `
})
export class CustomFieldWrapper extends FieldWrapper {
    constructor(){
        super();
    }
}