<div [ngStyle]="{display: isSlideOver ? 'block' : 'none'}" class="relative z-10" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div (click)="toggleSlideOver()" class="fixed inset-0"></div>
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
        <div class="pointer-events-auto w-screen max-w-4xl">
          <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
            <div class="px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 class="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">Business Chat</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button type="button" (click)="toggleSlideOver()"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                      aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="relative mt-6 flex-1 px-4 sm:px-6">
              <div class="h-[90%]">
                <div class="" id="chat-container"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



