<div *ngIf="event" class="flex flex-col gap-y-5 mx-auto max-w-6xl px-3 sm:px-6 lg:px-8">
  <div>
    <div *ngIf="loaded">
      <img class="h-2/3 w-full object-cover rounded-lg shadow-sm" [src]="event.theme.banner.name | image | async"
        alt="">
    </div>
    <div *ngIf="!loaded" class="flex items-center justify-center space-x-2 m-16">
      <div role="status">
        <svg aria-hidden="true" class="inline w-12 h-12 text-gray-200 animate-spin fill-main2" viewBox="0 0 100 101"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill" />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div>
    <div class="overflow-hidden grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
      <div class="flex flex-col gap-y-5">
        <div class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
            </svg>
            {{"EVENT_PAGE.DATE" | translate}}
          </h3>
          @if(!isEventOneDay()){
          <p class="text-lg">
            {{ event?.schedule?.from | date: 'dd ' }} - {{ event?.schedule?.to | date: 'dd '}}
            {{ 'MONTHS.' + (event?.schedule?.from | date: 'MMMM') | translate }}
            {{event?.schedule?.from | date: 'y' }}
          </p>
          } @else {
          <p class="text-lg">
            {{ event?.schedule?.from | date: 'dd ' }} {{ 'MONTHS.' + (event?.schedule?.from | date: 'MMMM') | translate
            }}
            {{event?.schedule?.from | date: 'y' }}
          </p>
          }
          <p class="text-lg">
            {{ event?.schedule?.from | date: 'shortTime':event?.schedule?.timeZone?.offset }} - {{ event?.schedule?.to |
            date: 'shortTime':event?.schedule?.timeZone?.offset }}
            (UTC {{event?.schedule?.timeZone?.offset == 'Z' ? '0' : event?.schedule?.timeZone?.offset}})
          </p>

          <button (click)="addToCalendar()" type="button"
            class="mt-2 rounded-md bg-white py-1.5 text-sm font-semibold text-main hover:text-main/70 ring-1 ring-inset ring-white hover:bg-gray-50">
            {{"EVENT_PAGE.ADD_TO_CALENDAR" | translate}}
          </button>
        </div>
        <div class="section-bloc">
          <h3 class="flex items-center text-xs"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
            </svg>
            {{"EVENT_PAGE.DESCRIPTION" | translate}}</h3>
          <pre
            class="text-sm whitespace-pre-wrap break-words font-text leading-6 text-justify overflow-auto">{{ event.description }}</pre>
        </div>
        <div *ngIf="event?.brochures?.length > 0" class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg class="w-4 h-4 mr-1 mb-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
            </svg>
            {{"EVENT_PAGE.ATTACHMENTS" | translate}}
          </h3>
          <div>
            <ul role="list" class="divide-y divide-gray-100">
              @for (brochure of event.brochures ; track $index) {
              <li (click)="download('api/uploads/' + brochure.name, brochure.originalFileName)"
                class="flex items-center justify-between gap-x-6 py-5 hover:cursor-pointer">
                <div class="flex flex-col min-w-0">
                  <p class="text-sm font-semibold leading-6 text-gray-900">{{ brochure.originalFileName }}</p>
                  <div class="min-w-0 flex-auto">
                    <p class="truncate text-xs leading-5 text-gray-500">{{ brochure.sizeMb == '0 Mb' ? 'Less than 1Mb' :
                      brochure.sizeMb}}</p>
                  </div>
                </div>
                <a (click)="download('api/uploads/' + brochure.name, brochure.originalFileName)"
                  class="hover:cursor-pointer px-2.5 py-1 text-xs font-semibold text-blue-500 hover:text-blue-700">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd"
                      d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.25 6a.75.75 0 0 0-1.5 0v4.94l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V9.75Z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
              }
            </ul>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-y-5">
        <div class="section-bloc" *ngIf="event?.nature !== 'Virtual'">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
            </svg>

            {{"EVENT_PAGE.EVENT_LOCATION" | translate}}
          </h3>
          <p class="text-base text-justify">
            {{ event?.location?.address }} {{ event.location.city }}, <span *ngIf="event?.location?.state">{{
              event.location.state
              }},</span> {{ event?.location?.country?.name }}
          </p>
        </div>

        <div class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
            </svg>
            {{"EVENT_PAGE.ORGANISATION" | translate}}
          </h3>
          <p class="text-base text-justify">
            {{ event?.community?.organisation?.name }}
          </p>
        </div>

        <div class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
            </svg>
            {{"EVENT_PAGE.COMMUNITY" | translate}}
          </h3>
          <p class="text-base text-justify text">
            {{ event?.community?.name }}
          </p>
        </div>

        <div class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
            </svg>

            {{"EVENT_PAGE.TAGS" | translate}}
          </h3>
          <p class="text-base text-justify">
            <span *ngFor="let tag of event.tags"
              class="mr-1.5 mb-0.5 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-black">
              {{ tag?.label }} </span>
            <span
              class="mr-1.5 mb-0.5 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-black">{{
              event?.type }}</span>
            <span
              class="mr-1.5 mb-0.5 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-black">{{
              event?.nature }}</span>
          </p>
        </div>

        <!-- <div class="section-bloc">
          <h3 class="text-xs">{{"EVENT_PAGE.COMMUNITY" | translate}}</h3>
          <p class="text-base text-justify text">
            {{ event?.community?.name }}
          </p>
        </div> -->


        <div class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
            </svg>
            {{"EVENT_PAGE.SHARE" | translate}}
          </h3>
          <p>
            <share-buttons [theme]="'default'"
              [include]="['facebook','x','linkedin','pinterest','reddit','tumblr','mix','viber','vk','telegram','messenger','whatsapp','xing','line','sms','email','print','copy']"
              [show]="8" [title]="event?.name" [image]="socialImgUrl" [description]="event.description"
              [url]="pageUrl"></share-buttons>
          </p>
        </div>
        <div class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>
            {{"EVENT_PAGE.CONTACT_US" | translate}}
          </h3>
          <p class="text-sm">
            {{"EVENT_PAGE.OR_CONTACT" | translate}} <a href="mailto:help@appsaya.com" class="text-main"> help&#64;appsaya.com</a>
          </p>
        </div>
        <div class="section-bloc">
          <h3 class="flex items-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>
            Sponsors
          </h3>
          <div>
            <ul role="list"
              class="mt-3.5 mx-auto grid grid-cols-1 gap-x-6 gap-y-11 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8">
              <li *ngFor="let sponsor of event.sponsors">
                <div>
                  <img *ngIf="sponsor.logo?.name" class="aspect-[3/2] w-full rounded-2xl object-cover"
                    [src]="sponsor.logo.name | image | async" alt="">
                  <svg *ngIf="!sponsor.logo" class="aspect-[3/2] w-full rounded-2xl object-cover text-slate-400"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                  </svg>
                  <h3 class="mt-6 text-lg text-center font-semibold leading-8 text-gray-900">{{ sponsor.name }}</h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 overflow-hidden">
      @if(forms.length > 1){
      <div class="section-bloc" id="registration_bloc">
        <h3 class="flex items-center text-xs">
          <svg class="w-4 h-4 mr-1 mb-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6" />
          </svg>
          {{"EVENT_PAGE.FORMS" | translate}}
        </h3>
        <div class="mt-7 grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-4">
          @for (form of forms; track form.id) {
          <event-registration-form [form]="form"></event-registration-form>
          }
        </div>
      </div>
      }
    </div>
  </div>




  <div class="bg-white rounded-lg shadow-inner border border-gray-400 sticky bottom-0 z-20 mt-28 py-6 sm:px-6 p-1.5">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1 flex flex-col items-center md:items-start justify-center">
        <h2 class="text-2xl md:text-3xl font-bold text-slate-700 sm:truncate sm:tracking-tight ">{{ event?.name }}</h2>
        <div class="my-1.5">
          <p class="flex items-center text-xs font-medium text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 mr-1 mb-0.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
            </svg>
            @if(!isEventOneDay()){
            {{ event?.schedule?.from | date: 'dd ' }} - {{ event?.schedule?.to | date: 'dd '}}
            {{ 'MONTHS.' + (event?.schedule?.from | date: 'MMMM') | translate }}
            {{event?.schedule?.from | date: 'y' }}
            } @else {
            {{ event?.schedule?.from | date: 'dd ' }} {{ 'MONTHS.' + (event?.schedule?.from | date: 'MMMM') | translate
            }}
            {{event?.schedule?.from | date: 'y' }}
            }
          </p>
        </div>
      </div>
      <div class="mt-4 flex md:ml-4 md:mt-0 justify-center md:justify-end">
        @if(forms.length == 1 ){
        <button [routerLink]="['./register', forms[0].id]" type="button"
          class="rounded-full w-64 md:w-52 ml-2.5 text-xl hover:bg-darker-green bg-main3 px-3.5 py-5 font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main3">
          {{"EVENT_PAGE.REGISTERATION" | translate}}
        </button>
        } @else {
        <button (click)="jumpToSection('registration_bloc')" type="button"
          class="rounded-full w-64 md:w-52 ml-2.5 text-xl hover:bg-main/75 bg-main px-3.5 py-3 font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
          {{"EVENT_PAGE.REGISTERATION" | translate}}
        </button>
        }
      </div>
    </div>
  </div>
</div>
