import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadChatUi } from 'chatkitty';
import { ChatKitty } from 'chatkitty-platform-sdk';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppSettingService } from './app-setting.service';
import { ChatKittySdkService } from './chat-kitty-sdk.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  toggleChat: BehaviorSubject<boolean> = new BehaviorSubject(false);

  chatkitty: ChatKitty;
  chatUI;

  constructor(
    private chatKittySDK: ChatKittySdkService,
    private sessionService: SessionService,
    private appSettings: AppSettingService,
    private httpClient: HttpClient
  ) { }

  async initChatKitty() {
    if (this.chatkitty) return Promise.resolve(true);

    try {
      await this.chatKittySDK.init();
      this.chatkitty = this.chatKittySDK.chatkitty;
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.resolve(false);
    }
  }

  async loadUI(containerId: string) {
    if (this.chatUI) return Promise.resolve(this.chatUI);
    const username = this.sessionService.session.value.email;
    this.chatUI = await loadChatUi(
      {
        widgetId: environment.chatKitty.widgetId,
        username: username,
        container: {
          id: containerId,
          height: '100%'
        },
      },
      {
        $environment: {
          mode: environment.chatKitty.environment.mode,
          origin: environment.chatKitty.environment.origin,
          environment: environment.chatKitty.environment.environment,
        },
      },
    );
  }

  unMountUI() {
    if (this.chatUI) {
      this.chatUI.unmount();
    }
  }

  startOneToOne(profileId: string) {
    const url: string = `${this.appSettings.settings.url}/chat/one-to-one?partnerProfileId=${profileId}`;
    const api$ = this.httpClient.post(url, {});
    return api$.pipe();
  }

  openChat() {
    this.toggleChat.next(true);
  }

  closeChat() {
    this.toggleChat.next(false);
  }
}
