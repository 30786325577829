import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ChatService } from 'src/app/shared/services/chat.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

  isSlideOver = false;
  subscription: Subscription;
  isUILoaded: boolean = false;
  timeoutRef

  constructor(
    private chatService: ChatService
  ) { }

  ngOnInit(): void {
    this.chatService.toggleChat.subscribe({
      next: status => {
        this.isSlideOver = status;
        if (this.isSlideOver && !this.isUILoaded) {
          setTimeout(() => {
            this.loadUI();
          }, 500);
        }
      },
    });
  }

  loadUI() {
    this.chatService.initChatKitty().then(
      _ => {
        this.chatService.loadUI('chat-container').then(
          _ => this.isUILoaded = true,
        )
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  toggleSlideOver() {
    this.isSlideOver = !this.isSlideOver;
  }
}
